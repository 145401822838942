<template>
	<div class="preOrder-detail">
        <div class="page-header">
            <div class="header-detail">
                <div class="header-port">
                    <div class="tc">
                        <div class="code">{{dep}}</div>
                        <div class="c9">始发港</div>
                    </div>
                    <div class="tc c6">
                        <div v-if="!!airline">
                            <div class="air-code">{{airline}}</div>
                            <div class="center">
                                <div class="dashed"></div>
                                <div class="transit tc" v-if="!!transit">中转</div>
                                <div class="transit tc" v-else>直飞</div>
                                <div class="dashed"></div>
                            </div>
                            <div class="transit-name" v-if="!!transit">{{transit}}</div>
                        </div>
                        <div v-else>
                            <div class="iconfeiji800 iconfont"></div>
                        </div>
                    </div>
                    <div class="tc">
                        <div class="code">{{des}}</div>
                        <div class="c9">目的港</div>
                    </div>
                </div>
                <div class="header-msg">
                    <div class="detail-msg">
                        <div class="msg-box">
                            <div class="box-top">
                                <div class="iconfont iconriqi"></div>日期
                            </div>
                            <div class="msg-value">
                                {{ deliverDate }}
                            </div>
                        </div>
                        <div class="msg-box">
                            <div class="box-top">
                                <div class="iconfont icontijikongjian-xianxing"></div>体积(m³)
                            </div>
                            <div class="msg-value">{{ volume }}</div>
                        </div>
                        <div class="msg-box">
                            <div class="box-top">
                                <div class="iconfont iconmaozhong"></div>毛重(KG)
                            </div>
                            <div class="msg-value">{{ gross_weight }}</div>
                        </div>
                        <div class="msg-box">
                            <div class="box-top">
                                <div class="iconfont iconjianshu"></div>件数
                            </div>
                            <div class="msg-value">{{ piece }}</div>
                        </div>
                    </div>
                    <div class="remarks" v-if="!!preOrder.remark">备注：{{preOrder.remark}}</div>
                </div>
            </div>
            
        </div>
        <div class="preOrder-content">
            <div class="detail-price" v-if="!!quotation">
                <van-cell title="单询运价" :border="false" center v-if="!product || product.price_type==0 || quotation.price_type==0">
                    <div class="custom">
                        <span style="font-size: 0.22rem;">￥</span>{{custom_price}}
                    </div>
                </van-cell>
                <van-cell title="运价" :border="false" class='custom-price' v-else-if="quotation.price_type==1">
                    <div class="custom-content">
                        <div class="price-input tc">
                            <div class="c9">+45</div>
                            <div class="price-box">
                                {{ q1>0 ? q1 :'-' }}
                            </div>
                        </div>
                        <div class="price-input tc">
                            <div class="c9">+100</div>
                            <div class="price-box">
                                {{ q2>0 ? q2 :'-' }}
                            </div>
                        </div>
                        <div class="price-input tc">
                            <div class="c9">+300</div>
                            <div class="price-box">
                                {{ q3>0 ? q3 :'-' }}
                            </div>
                        </div>
                        <div class="price-input tc">
                            <div class="c9">+500</div>
                            <div class="price-box">
                                {{ q4>0 ? q4 :'-' }}
                            </div>
                        </div>
                        <div class="price-input tc">
                            <div class="c9">+1000</div>
                            <div class="price-box">
                                {{ q5>0 ? q5 :'-' }}
                            </div>
                        </div>
                    </div>
                </van-cell>
            </div>
            <div class="separationRatio border-bottom" v-if="!!quotation.pct">
                <van-cell title="分泡" :border="false" center>
                    <p>{{quotation.pct}}</p>
                </van-cell>
            </div>
            <div class="freight-list" v-if="feeList.length>0">
                <div class="freight-title">杂费清单</div>
                <van-cell-group :border="false">
                    <van-cell input-align="right" center class="total-price">
                        <div slot="title">
                            <div class="van-cell-text c3">合计 <span v-if="!!rate && rate!=1">（汇率：{{rate}}）</span></div>
                        </div>
                        <div class="van-cell-value" v-if="amount>0"><span>￥</span>{{amount}}</div>
                    </van-cell>
                    <div v-for="(item, index) in feeList" :key="index">
                        <van-cell :title="item.chargeName" :border="false" input-align="right">
                            <div class="van-cell-txt">
                                <div>{{item.currencyUnit}}:  {{item.chargePrice}}</div>
                                <div class="price-desc" v-if="!item.chargeUnit">{{item.chargePrice}} x {{item.number}} {{item.chargeUnitDesc}}</div>
                                <div class="price-desc" v-else-if="item.chargeUnit==0">{{item.chargePrice}} x 1 件</div>
                                <div class="price-desc" v-else-if="item.chargeUnit==1">{{item.chargePrice}} x 1 票/单</div>
                                <div class="price-desc" v-else-if="item.chargeUnit==3">{{item.chargePrice}} x 1 公斤</div>
                                <div class="price-desc" v-else-if="item.chargeUnit==4">{{item.chargePrice}} x 1 提单</div>
                                <div class="price-desc" v-else-if="item.chargeUnit==5">{{item.chargePrice}} x 1 叉</div>
                            </div>
                        </van-cell>	
                    </div>
                </van-cell-group>
            </div>
            <div class="remark-cell " v-if="!!quotation.remark">
                <div class="remark-title">商家备注</div>
                <div class="remark-cont">{{quotation.remark}}</div>
            </div>
        </div>
        
        <div class="shop-msg">
            <div class="shop-msg-top">
                <div class="shop-msg-left">
                    <van-image
                        round
                        width="0.94rem"
                        height="0.94rem"
                        :src="!!agentInfo.avatar ? 'http://' + agentInfo.avatar :'https://doc.yctop.com/weapp/zjhy/static/2021-1-26/avatar-h5.png'"
                        @click="toShopHome"
                    />
                    <div class="msg-title">
                        <p>{{agentInfo.name}}</p>
                        <p class="fans">
                            <span class="fans-name">粉丝数</span>
                            <span>{{agentInfo.fanCnt}}</span>
                        </p>
                    </div>
                </div>
                <div class="shop-msg-right">
                    <van-button :class="agentInfo.favored ? 'favor-btn favored':'favor-btn no-attention'" @click="favorChange(agentInfo.favored)">
                        {{agentInfo.favored ? '已关注':'关注'}}
                    </van-button>
                    <van-button class="favor-btn to-shop" @click="toShopHome">
                        进店
                    </van-button>
                </div>
            </div>
            <div class="shop-msg-bottom" v-if="agentInfo.company_title && !!agentInfo.company_title">
                <van-icon class-prefix="iconqiye iconfont" />
                {{agentInfo.company_title}}
            </div>
        </div>
        <div class="add-height"></div> 
        <div class="detail-bottom">
			<van-button class="bottom-btn bottom-call" @click="toShopHome">
				<van-image
					width="0.32rem"
					height="0.32rem"
					src="https://doc.yctop.com/weapp/zjhy/static/2021-1-21/shop.png"
				/>
				<p class="c6">店铺</p>
			</van-button>
			<a :href="agentContact ? ('tel:' + agentContact) : '#'" >
                <van-button class="bottom-btn bottom-call">
                    <van-image
                        width="0.29rem"
                        height="0.28rem"
                        src="https://doc.yctop.com/weapp/zjhy/static/2021-1-21/phone.png"
                    />
                    <p class="c6">电话</p>
                </van-button>
            </a>
			<div>
                <div v-if="!!quotation">
                    <van-button  class="bottom-btn bottom-create" type="info" v-if="quotation.status==1" disabled >已确认报价</van-button>
                    <van-button class="bottom-btn bottom-create" @click="confirmOrder" v-else>确认报价</van-button>
                </div>
                <div v-else>
                    <van-button  class="bottom-btn bottom-create" type="info" disabled >未报价</van-button>
                </div>
            </div>
		</div>
        
    </div>
	
</template>
<script>
	/* eslint-disable */
	import { mapState, mapGetters } from 'vuex';
	import { Dialog } from 'vant';
    import  REQ_HANDLE from '@/utils/request.js'
    import UTILS from '@/utils/common-utils';
	export default {
		data: ()=> ({
			preOrderId:'',
            dep:'',
            des:'',
            volume:'',
            gross_weight:'',
            piece:'',
            custom_price: '',
            q1:'0',
            q2:'0',
            q3:'0',
            q4:'0',
            q5:'0',
            amount:'',
            feeList:[],
            rate:1,
            dollar: 0,
            cny: 0,
            deliver_date:'',
            deliverDate:'',
            quotation:{},
            product:{},
            preOrder:{},
            shopId:'',
            agentContact:"",
            agentInfo: {
				avatar: '',
				name: '',
				company_title:'',
				fanCnt:0,
				favored:false,
            },
            airline:'',
            transit:'',
           
		}),
		computed: {
			...mapState({
				usr: state => state.usr,
				token: state => state.token,
				wx_usr: state => state.wx_usr,
			}),
			...mapGetters({
				login: 'checkLogin',
			})
		},
		created: async function() {
            this.shopId=this.$route.params.shopId
            this.preOrderId=this.$route.params.preOrderId
            !!this.shopId && await this.$store.dispatch('getAgentName', {shopId:this.shopId})
            !!this.shopId && (this.agentInfo = await this.$store.dispatch('getAgentInfo',{shopId:this.shopId}) )
		},
		mounted: async function() {
            await this.loadDetail()
            this.agentContact=await UTILS.getAgentContact(this.shopId)
		},
		methods: {
            loadDetail: async function() {
				let url = '/api-mall/quotation/'+this.preOrderId,
					res = await REQ_HANDLE.agent.$get(url),
					that=this
				if(!!res) {
                    that.preOrder=res.data.preOrder
                    that.product=res.data.product || ''
                    that.quotation=res.data.quotation||''
                    // that.preOrder = Object.assign({}, this.preOrder, res.data.preOrder)
                    that.dep= res.data.preOrder.dep
                    that.des= res.data.preOrder.des
                    if(!!res.data.quotation){
                        //  that.quotation = Object.assign({}, this.quotation, res.data.data.quotation)
                        let arr =JSON.parse(res.data.quotation.fee_list_string)
                        that.feeList=arr
                        if(res.data.quotation.price_type==0){
                            that.custom_price=res.data.quotation.custom_price ? res.data.quotation.custom_price:''
                        }else{
                            that.q1= res.data.quotation.q1 ? res.data.quotation.q1:'0'
                            that.q2= res.data.quotation.q2 ? res.data.quotation.q2:'0'
                            that.q3= res.data.quotation.q3 ? res.data.quotation.q3:'0'
                            that.q4= res.data.quotation.q4 ? res.data.quotation.q4:'0'
                            that.q5= res.data.quotation.q5 ? res.data.quotation.q5:'0'
                        }
                        that.amount=res.data.quotation.amount ? res.data.quotation.amount:''
                        that.volume=res.data.quotation.volume ? res.data.quotation.volume:''
                        that.gross_weight=res.data.quotation.gross_weight ? res.data.quotation.gross_weight:''
                        that.piece=res.data.quotation.piece ? res.data.quotation.piece:''
                        that.deliver_date=res.data.quotation.deliver_date || ''
                        that.deliverDate=res.data.quotation.deliver_date ?res.data.quotation.deliver_date.substring(res.data.quotation.deliver_date.length-5).replace( new RegExp('-','g'), '.'):'' 
                        that.airline=!!res.data.quotation.airline ? res.data.quotation.airline : (!!res.data.product && !!res.data.product.airline ? res.data.product.airline :'') 
					    that.transit=!!res.data.quotation.transit && res.data.quotation.transit !='undefined' ? res.data.quotation.transit : ''
                    }else{
                        let feeUrl= "/api-mall/proxy/yctop/charge/list",
                        params={
                            departure:that.dep
                        },
                        result = await REQ_HANDLE.agent.$get(feeUrl,params)
                        if(!!result){
                            that.feeList=result.data.data.chargeList || []
                            that.feeList.length && that.feeList.length>0 && that.summation( that.feeList)
                        }
                        if(!!res.data.product){
                            that.airline=!!res.data.product.airline ? res.data.product.airline : ''
					        that.transit=!!res.data.product.transit && res.data.product.transit !='undefined' ? res.data.product.transit : ''
                            if( res.data.product.price_type==0){
                                that.custom_price=res.data.product.custom_price ? res.data.product.custom_price:''
                            }else {
                                that.q1= res.data.product.q1 ? res.data.product.q1:'0'
                                that.q2= res.data.product.q2 ? res.data.product.q2:'0'
                                that.q3= res.data.product.q3 ? res.data.product.q3:'0'
                                that.q4= res.data.product.q4 ? res.data.product.q4:'0'
                                that.q5= res.data.product.q5 ? res.data.product.q5:'0'
                            }
                        }
                        that.volume=res.data.preOrder.volume ? res.data.preOrder.volume:''
                        that.gross_weight=res.data.preOrder.gross_weight ? res.data.preOrder.gross_weight:''
                        that.piece=res.data.preOrder.piece ? res.data.preOrder.piece:''
                        that.deliver_date=res.data.preOrder.deliver_date || ''
                        that.deliverDate=res.data.preOrder.deliver_date ?res.data.preOrder.deliver_date.substring(res.data.preOrder.deliver_date.length-5).replace( new RegExp('-','g'), '.'):''
                        }
                   
				}
            },
            summation: function(array){
                let dollar=0,
                    cny=0,
                    type=''
                for (var i = 0; i < array.length; i++) {
                    array[i].unitPrice=array[i].chargePrice
                    if(!array[i].chargeUnit && array[i].number){
                        array[i].unitPrice=Number( array[i].unitPrice* array[i].number)
                    }else if (array[i].chargeUnit == 3 && !!this.gross_weight) {
                        array[i].unitPrice = Number((array[i].unitPrice * this.gross_weight).toFixed(2))
                        if(!!array[i].miniPrice){
                            array[i].unitPrice = array[i].unitPrice < array[i].miniPrice ? array[i].miniPrice : array[i].unitPrice;
                        }
                    }
                    if(array[i].currencyUnit=='CNY'){
                        cny += Number( array[i].unitPrice);
                    } else{
                        type=array[i].currencyUnit
                        dollar += Number( array[i].unitPrice);
                        
                    } 
                }
                if(dollar==0){
                    this.amount=Number(cny)
                }else{
                    this.dollar=dollar
                    this.cny=cny
                    this.loadRate(type)
                }
            },
            confirmOrder: async function(){
               let url = '/api-mall/quotation/'+this.preOrderId+'/confirm',
					res = await REQ_HANDLE.agent.$put(url),
                    that=this
				if(!!res) {
                    that.$router.push({ path: '/historyList'})
                }
            },
            loadRate: async function (currency) {
                let _this = this,
                    url = '/ae/exchangeRate?exchangeRateDate',
                    res = await REQ_HANDLE.agent.$get(url)
                if(!!res){
                     for (var i = 0; i < res.data.data.length; i++) {
                        if(currency == res.data.data[i].currency){
                            _this.rate=res.data.data[i].exchangeRate
                            _this.amount=Number( ( _this.dollar* exchangeRate).toFixed(2) )+Number(_this.cny)
                        }
                        
                    }
                }
            },
            toShopHome:function(){
				!!this.shopId && this.$router.push('/shop/'+this.shopId)
            },
			favorChange: async function(favored){
				favored==true ? this.cancelFavored() : await this.isFavored()
			},
			isFavored: async function(){
				let url = '/api-mall/favor',
					params={
						agent_id: this.shopId
                    },
                    _this=this,
					res = await REQ_HANDLE.agent.$post(url, params)
				if(!!res){
					_this.agentInfo = await _this.$store.dispatch('getAgentInfo',{shopId:_this.shopId})
				}
			},
			cancelFavored: function(){
				let _this=this
				Dialog.confirm({
					message: '确认取消关注？',
					confirmButtonText:'取消关注',
					cancelButtonText:'再想想',
				})
				.then( async () => {
					let url = '/api-mall/favor/' + this.shopId,
					res = await REQ_HANDLE.agent.$delete(url)
					if(!!res){
						_this.agentInfo = await _this.$store.dispatch('getAgentInfo',{shopId:_this.shopId})
					}
				})
				.catch(() => {
					// on cancel
				});
			},
		}
			
	};
</script>
<style scoped >
@import "~@/assets/css/preOrderDetail.css";
</style>